import { useCallback } from "react";
import Link from "next/link";
import Image from "@/components/Image";
import Tag from "@/components/tag/Tag";
import { ARTICLE_LINK_CLICKED } from "@/constants/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import { Article } from "@/graphql/gql/graphql";
import ArticleCardPublish from "./ArticleCardPublish";
import cx from "classnames";
import styles from "./articleCard.module.scss";

type ArticleCardProps = Pick<Article, "title"> & {
  author: Pick<Article["author"], "id" | "name" | "slug">;
  image?: string;
  link: string;
  origin: string;
  publishedOn?: Article["firstPublishedAt"];
  showImage?: boolean;
  sportName: string;
  sportSlug?: string;
  description?: string;
  addBackground?: boolean;
  displayHorizontal?: boolean;
  hasPadding?: boolean;
  showAuthor?: boolean;
};

const ArticleCard = ({
  author,
  image = "/research/images/fd_logo.webp",
  link,
  origin,
  publishedOn,
  showImage = true,
  sportName,
  sportSlug,
  title,
  description,
  addBackground,
  displayHorizontal = false,
  hasPadding = true,
  showAuthor = true,
}: ArticleCardProps) => {
  const { logAmplitudeEvent } = useAmplitude();
  const onClick = useCallback(
    () =>
      logAmplitudeEvent(ARTICLE_LINK_CLICKED, {
        "Link Text": title,
        "Link URL": link,
        Module: origin,
        Competition: sportName,
      }),
    [link, logAmplitudeEvent, origin, sportName, title]
  );

  return (
    <div
      className={cx(
        styles.container,
        addBackground && styles.background,
        displayHorizontal && styles.horizontal,
        hasPadding && styles.padding
      )}
    >
      <div className={styles.text}>
        {sportSlug && (
          <Tag intent="neutral" label={sportName} href={`/${sportSlug}`} />
        )}
        <h2 className={styles.title}>
          <Link href={link} prefetch={false} onClick={onClick}>
            {title}
          </Link>
        </h2>
        <ArticleCardPublish
          author={author}
          date={publishedOn}
          showAuthor={showAuthor}
        />
        {description && <p className={styles.description}>{description}</p>}
      </div>
      {showImage && (
        <div className={styles.imageContainer}>
          <Link href={link} prefetch={false} onClick={onClick}>
            <Image
              alt={title}
              fill
              src={image}
              sizes="285px, (max-width: 960px) 133px"
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default ArticleCard;

import Link from "next/link";
import {
  Tag as FormationTag,
  TagProps as FormationTagProps,
} from "@fanduel/formation-react-components";

type TagProps = {
  label: string;
  href?: string;
} & Pick<FormationTagProps, "intent">;

const Tag = ({ intent = "neutral", label, href }: TagProps) => {
  if (!href) {
    return (
      <FormationTag
        variant={intent === "neutral" ? "subtle" : "fill"}
        intent={intent}
      >
        {label}
      </FormationTag>
    );
  }

  return (
    <Link href={href}>
      <FormationTag
        variant={intent === "neutral" ? "subtle" : "fill"}
        intent={intent}
      >
        {label}
      </FormationTag>
    </Link>
  );
};

export default Tag;

import { useCallback } from "react";
import cx from "classnames";
import Link from "next/link";
import Image from "@/components/Image";
import Tag from "@/components/tag/Tag";
import ArticleCardPublish from "@/components/articleCard/ArticleCardPublish";
import { ARTICLE_LINK_CLICKED } from "@/constants/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import { ContentAuthor, Maybe } from "@/graphql/gql/graphql";
import styles from "./Spotlight.module.scss";

type TagType = "none" | "neutral" | "info";

export type SpotlightArticleProps = {
  author: ContentAuthor;
  image?: string;
  link: string;
  origin: string;
  publishedOn?: Maybe<string>;
  snippet?: string;
  sportName: string;
  sportSlug?: string;
  tagType?: TagType;
  title: string;
  fullwidthMobileImage: boolean;
  addBackground?: boolean;
};

const SpotlightArticle = ({
  author,
  image = "/research/images/fd_logo.webp",
  link,
  publishedOn,
  origin,
  sportName,
  sportSlug,
  snippet,
  tagType = "none",
  title,
  fullwidthMobileImage = false,
  addBackground = false,
}: SpotlightArticleProps) => {
  const containerClass = cx(styles.container, {
    [styles.background]: addBackground,
  });

  const imgContainerClass = cx(
    fullwidthMobileImage ? styles.paddingLg : styles.padding
  );

  const spotlightImgClass = cx(
    styles.spotlightImg,
    fullwidthMobileImage ? styles.roundedLg : styles.rounded
  );

  const { logAmplitudeEvent } = useAmplitude();
  const onClick = useCallback(
    () =>
      logAmplitudeEvent(ARTICLE_LINK_CLICKED, {
        "Link Text": title,
        "Link URL": link,
        Module: origin,
        Competition: sportName,
      }),
    [link, logAmplitudeEvent, origin, sportName, title]
  );

  const TagMap = {
    none: null,
    info: (
      <Tag
        intent="info"
        label={`${sportName} Spotlight`}
        href={`/${sportSlug}`}
      />
    ),
    neutral: <Tag intent="neutral" label={sportName} href={`/${sportSlug}`} />,
  };

  return (
    <div className={containerClass}>
      <Link href={link} prefetch={false} onClick={onClick}>
        <div className={imgContainerClass}>
          <Image
            priority
            className={spotlightImgClass}
            alt={title}
            width={800}
            height={450}
            src={image}
            sizes="(min-width: 1440px) 940px,(min-width: 1280px) 640px, 100vw"
          />
        </div>
      </Link>
      <div className={styles.articleInfo}>
        {tagType && sportSlug && TagMap[tagType]}
        <h2 className={styles.title}>
          <Link prefetch={false} href={link}>
            {title}
          </Link>
        </h2>
        <ArticleCardPublish author={author} date={publishedOn} />
        <div className={styles.snippet}>{snippet}</div>
      </div>
    </div>
  );
};

export default SpotlightArticle;

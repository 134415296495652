import { ContentAuthor, Maybe } from "@/graphql/gql/graphql";
import { relativeDate } from "@/utils/date";
import Link from "@/components/link/Link";
import styles from "./articleCardPublish.module.scss";
import { BodyText } from "@fanduel/formation-react-components";

type ArticlePublishProps = {
  author: ContentAuthor;
  date?: Maybe<string>;
  showAuthor?: boolean;
};

const ArticleCardPublish = ({
  author,
  date,
  showAuthor = true,
}: ArticlePublishProps) => {
  const { slug } = author;

  if (!showAuthor) {
    return (
      <div className={styles.container}>
        {date && (
          <BodyText size="sm">
            <time dateTime={date} suppressHydrationWarning>
              {relativeDate(date)}
            </time>
          </BodyText>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {slug ? (
        <Link next href={`/author/${slug}`}>
          <BodyText size="sm">{author.name}</BodyText>
        </Link>
      ) : (
        <BodyText size="sm">{author.name}</BodyText>
      )}
      {date && (
        <BodyText size="sm">
          <time dateTime={date} suppressHydrationWarning>
            {` • ${relativeDate(date)}`}
          </time>
        </BodyText>
      )}
    </div>
  );
};

export default ArticleCardPublish;

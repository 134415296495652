import cx from "classnames";
import { PropsWithChildren } from "react";
import styles from "./common.module.scss";

export const ScrollItem = ({
  children,
  isHidden,
}: PropsWithChildren<{ isHidden?: boolean }>) => (
  <div className={cx(isHidden && styles.scrollItem)}>{children}</div>
);

export const HideAtMd = ({ children }: PropsWithChildren) => (
  <div className={styles.hideAtMd}>{children}</div>
);
